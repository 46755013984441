<template>
  <div class="write">
    <!-- height를 비율 단위로 변경 생각 -->
    <div class="letter__photo" ref="printMe">
      <div class="letter">
        <div class="letter__to">
          <p class="letter__title">TO</p>
          <div class="letter__input__wrapper">
            <input
              type="text"
              name="to"
              class="letter__input"
              v-model="$store.state.to"
            />
            <img
              src="@/assets/to_line.svg"
              alt="line"
              width="100%"
              class="input__line"
            />
          </div>
        </div>

        <div
          id="text"
          contenteditable="true"
          class="letter__textarea"
          crossorigin="Anonymous"
        ></div>

        <div class="letter__from">
          <p class="letter__title">FROM</p>
          <div class="letter__input__wrapper">
            <input
              type="text"
              name="from"
              class="letter__input"
              v-model="$store.state.from"
            />
            <img
              src="@/assets/to_line.svg"
              alt="line"
              width="100%"
              class="input__line"
            />
          </div>
        </div>
      </div>
      <div class="image">
        <img class="bg1" src="@/assets/bg1.svg" alt="bg1" />
        <img class="bg2" src="@/assets/bg2.svg" alt="bg2" />
        <img class="bg1__mobile" src="@/assets/bg1_mobile.svg" alt="bg1" />
        <img class="bg2__mobile" src="@/assets/bg2_mobile.svg" alt="bg2" />
      </div>
    </div>
    <div class="button__wrapper">
      <div class="button" @click="print()">작성 완료</div>
      <div class="button" @click="back()">뒤로가기</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image-more";

export default {
  name: "Write",
  mounted() {
    if (this.$store.state.text != "" && this.$store.state.text != undefined) {
      document.getElementById("text").innerText = this.$store.state.text;
    }
  },
  methods: {
    printDomToImage() {
      var vm = this;
      let ele = this.$refs.printMe;

      domtoimage
        .toPng(ele)
        .then(function (dataUrl) {
          console.log(dataUrl);
          vm.$store.state.base64 = dataUrl;
          vm.$store.state.text = document.getElementById("text").innerText;

          var link = document.createElement("a");

          link.setAttribute("href", dataUrl);
          link.setAttribute("type", "image/png");
          link.setAttribute("download", "letter.png");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          vm.$router.push("/complete");
        })
        .catch(function (error) {
          window.alert("error");
          console.error("oops, something went wrong!", error);
        });
    },
    print() {
      var vm = this;
      let ele = this.$refs.printMe;
      var window = window;
      var image;
      var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

      html2canvas(ele, {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        try {
          var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
          if (
            varUA.indexOf("iphone") > -1 ||
            varUA.indexOf("ipad") > -1 ||
            varUA.indexOf("ipod") > -1
          ) {
            //IOS
            if (/CriOS/i.test(navigator.userAgent)) {
              alert(
                "아이폰 크롬 브라우저는 현재 지원하지 않습니다. 사파리를 이용해주세요."
              );
              return true;
            } else if (
              /kakaotalk/i.test(navigator.userAgent) ||
              /naver/i.test(navigator.userAgent) ||
              /instagram/i.test(navigator.userAgent) ||
              /messenger/i.test(navigator.userAgent)
            ) {
              let c = canvas;
              vm.$store.state.base64 = c.toDataURL("image/png");
              vm.$store.state.text = document.getElementById("text").innerText;
              vm.$router.push("/image");
              return true;
            } else {
              let c = canvas;
              var image = c.toDataURL("image/png");
              vm.$store.state.base64 = image;
              vm.$store.state.text = document.getElementById("text").innerText;
              var link = document.createElement("a");

              link.setAttribute("href", image);
              link.setAttribute("type", "image/png");
              link.setAttribute("download", "letter.png");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              vm.$router.push("/complete");
            }
          } else {
            let c = canvas;
            var image = c.toDataURL("image/png");
            vm.$store.state.base64 = image;
            vm.$store.state.text = document.getElementById("text").innerText;
            var link = document.createElement("a");

            link.setAttribute("href", image);
            link.setAttribute("type", "image/png");
            link.setAttribute("download", "letter.png");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            vm.$router.push("/complete");
          }
        } catch (e) {}
      });
    },
    back() {
      (this.$store.state.from = ""),
        (this.$store.state.text = ""),
        (this.$store.state.to = ""),
        this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.write {
  width: 100%;
  min-height: 100%;

  background-color: #f6f4e2;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-direction: column;
}
.letter {
  padding: 50px 30px;
  width: 100%;
  height: 100%;
}
.letter__to,
.letter__from {
  display: flex;
  align-items: center;

  font-family: "GmarketSansTTFLight";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.letter__photo {
  background-color: #f6f4e2;
  width: 100%;
  height: 100%;
}

.letter__from {
  justify-content: flex-end;
  z-index: 2;
  position: relative;
}
.letter__title {
  margin: 0px 8px 0px 0px;
}
.input__line {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -6px;

  max-width: 195px;
  min-width: 120px;
}
.letter__input {
  width: 200px;
  border: 0px;
  background-color: transparent;
  background-attachment: local;

  font-family: "GmarketSansTTF";

  font-size: 16px;

  margin-bottom: 2px;
}
.letter__input:focus {
  outline: none;
}

.letter__input__wrapper {
  position: relative;
}

.letter__textarea {
  width: 100%;
  min-height: 360px;
  height: 100%;

  font-family: "LeeSeoyun";
  font-weight: 400;
  font-size: 18px;

  color: #000000;

  margin: 60px 0px 60px 0px;

  border: 0px;
  resize: none;
  overflow: auto;
  background-color: transparent;

  background-attachment: local;
  background-image: url("../assets/line.png");
  background-position: top;
  background-size: auto;
  line-height: 36px;

  text-align: left;
}

.letter__textarea:focus {
  outline: none;
}

.image {
  width: 100%;
  height: 200px;
  position: relative;
}
.bg1 {
  position: absolute;
  left: -20px;
  top: -120px;
}
.bg2 {
  position: absolute;
  top: -60px;
  right: 0px;
}
.bg1__mobile {
  display: none;
  position: absolute;
  left: -20px;
  top: -120px;
}
.bg2__mobile {
  display: none;
  position: absolute;
  top: -60px;
  right: 0px;
}
.button__wrapper {
  width: 100%;
  padding: 0px 30px 50px 30px;
}

.button:nth-child(1) {
  width: 100%;
  height: 56px;
  border-radius: 10px;
  background-color: #ea5532;

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.button:nth-child(2) {
  width: 100%;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #ea5532;

  background-color: white;

  color: #ea5532;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-top: 10px;
}

@media screen and (max-width: 440px) {
  .image {
    height: 40vw;
  }
  .bg1,
  .bg2 {
    display: none;
  }

  .bg1__mobile,
  .bg2__mobile {
    display: block;
  }
}
</style>
